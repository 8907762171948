<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <!-- <ABreadcrumb :items="breadcrumbs"></ABreadcrumb> -->
    <!-- EOC -->
    <div class="mb-3">
      <span class="text-caption">活动结束：与学生们跟进</span>
    </div>
    <!-- BOC -->
    <div v-if="api.loading || !data" class="text-center pa-5">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-else-if="!selectedStudent">
      <div class="mb-3 text-caption">{{data.school.name}}, {{data.event.name}}</div>
      <div class="mb-3 text-h5">选择您的班级并点击开始</div>
      <div class="mt-5 d-flex justify-center align-start">
        <v-select
          dense
          outlined
          v-model="selectedClassroom"
          :items="data.classroom"
          item-text="name"
          item-value="id"
          label="班级"
        ></v-select>
        <div class="px-3 py-2">({{followedStudent}}/{{filteredStudent.length}})</div>
        <v-btn color="primary" @click="select(0)">开始跟进</v-btn>
      </div>
      <v-divider></v-divider>
      <v-simple-table>
        <thead>
          <tr>
            <th>#</th>
            <th>姓名</th>
            <th>班级</th>
            <th>状况</th>
            <th>作答次数</th>
            <th>星数</th>
            <th>跟进</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="i" v-for="(student,i) in filteredStudent">
            <td>{{i+1}}</td>
            <td>{{student.name}}</td>
            <td>{{student.Classroom.name}}</td>
            <td><v-chip small :color="(student.statusKey >= 50) ? 'success' : 'error'">{{processStatus(student.statusKey)}}</v-chip></td>
            <td>
              <span v-if="student.Participant">{{student.Participant.totalAttempt}}</span>
              <span v-else>-</span>
            </td>
            <td>
              <span v-if="student.Participant">{{student.Participant.totalStar}} ★</span>
              <span v-else>-</span>
            </td>
            <td>
              <v-btn :loading="api2.isLoading" text :color="(!student.needFollowup) ? 'gray' : (student.followup) ? 'success' : 'error'" small @click="select(i)">
                <span v-if="!student.needFollowup">不必跟进</span>
                <span v-else-if="student.followup">已跟进</span>
                <span v-else>需要更进</span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div v-else>
      <div class="d-flex mb-3 align-center">
        <v-btn color="primary" @click="select(null)">{{$t("action.cancel")}}</v-btn>
        <v-progress-linear
          v-model="followedPercentage"
          color="grey"
          height="24"
          class="ma-1 mx-10"
        >
          <strong>({{followedStudent}}/{{filteredStudent.length}})</strong>
        </v-progress-linear>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary"
          class="mr-1"
          @click="go(-1)" 
          :disabled="filteredStudent[0].id ==selectedStudent.id"
        >◀</v-btn>
        <v-btn 
          color="primary"
          @click="go(1)"
          :disabled="filteredStudent[filteredStudent.length-1].id ==selectedStudent.id"
        >▶</v-btn>
      </div>
      
      <Transition name="fade" mode="out-in">
        <template v-for="(s,si) in filteredStudent">
          <FollowupForm
            :index="si"
            :key="si"
            v-if="si == selectedStudentIndex"
            :student="s" 
            :status="processStatus(s.statusKey)"
            :school="data.school"
            :event="data.event"
          ></FollowupForm>
        </template>
      </Transition>
    </div>
    <!-- EOC -->
  </v-container>
</template>

<script>
import FollowupForm from '@/components/views/PageMainEventFollowup/FollowupForm'
import { mapState } from 'vuex'
export default {
  components: {
    FollowupForm,
  },
  computed: mapState({
    //
  }),
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api2]
    api2: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data:null,
    selectedClassroom:null,
    selectedStudent:null,
    selectedStudentIndex:null,
    filteredStudent:[],
    followedStudent:0,
    followedPercentage:0,
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainEvent"),
      to: { name: "PageMainEvent" },
      exact: true,
    });
    //
    if(this.$route.query.pw){
      this.breadcrumbs.push({
        text:this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: {
            code: this.$route.params.eventCode,
          },
          query: {
            pw: this.$route.query.pw,
          },
        },
        exact:true,
      })
    }
    else{
      this.breadcrumbs.push({
        text: this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: {
            code: this.$route.params.eventCode,
          },
        },
        exact: true,
      });
    }
    //EOC
    //BOC:[api]
    this.api.url = `${this.$api.servers.classroom}/v1/${this.$_getLocale()}/event/${this.$route.params.eventCode}/followup`;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      const { School, ClassroomOnEvent, Checkpoint, ...Event } = resp
      const classrooms = ClassroomOnEvent
      var Classrooms = []
      var Students = []
      for(var classroom of classrooms) {
        var { Student, ...Classroom } = classroom.Classroom
        Classrooms.push(Classroom)
        for(var student of Student) {
          this.processStudent(student, Checkpoint)
          Students.push(student)
        }
      }
      //
      Students = this.$_.sortBy(Students,['statusKey'],['desc'])
      //
      this.data = {
        event:Event,
        checkpoint:Checkpoint,
        school:School,
        classroom:Classrooms,
        student:Students,
      }
      //
      this.selectedClassroom = Classrooms[0].id
      //
      this.api2.url = `${this.$api.servers.log}/log/v1/${this.$_getLocale()}/followup/browseByEvent/${this.data.event.id}`;
      this.$api.fetch(this.api2)
    };
    //EOC
    //BOC:[api2]
    this.api2.callbackReset = () => {
      this.api2.isLoading = true;
      this.api2.isError = false;
    };
    this.api2.callbackError = (e) => {
      this.api2.isLoading = false;
      this.api2.isError = true;
      this.api2.error = e;
    };
    this.api2.callbackSuccess = (resp) => {
      this.api2.isLoading = false;
      this.processFollowup(resp)
    };
    //EOC
    this.$api.fetch(this.api)
  },
  mounted() {
    //
  },
  watch: {
    selectedClassroom(newVal) {
      this.chooseClassroom(newVal)
    },
    selectedStudentIndex(newVal) {
      this.selectedStudent = this.filteredStudent[newVal]
    },
    followedStudent(newVal) {
      this.followedPercentage = newVal/this.filteredStudent.length*100
    },
  },
  methods: {
    chooseClassroom(id) {
      this.filteredStudent = this.$_.filter(this.data.student,(o)=>{
        return o.classroomId == id
      })
      this.followedStudent = this.$_.filter(this.filteredStudent,(o)=>{
        return o.followup != null || o.needFollowup == false
      }).length
    },
    processFollowup(followup) {
      for(var student of this.data.student) {
        var fi = this.$_.findIndex(followup,(o)=>{return o.studentId == student.id})
        if(fi > -1) {
          student.followup = this.$_.clone(followup[fi])
          if(student.classroomId == this.selectedClassroom) this.followedStudent += 1
        }
        else {
          student.followup = null
        }
        //
        student.needFollowup = true
        if(student.statusKey >= 50) {
          student.needFollowup = false
          if(student.classroomId == this.selectedClassroom) this.followedStudent += 1
        }
      }
    },
    processStudent(student, checkpoint) {
      student.statusKey = null
      if(!student.Participant || student.Participant.length < 1) {
        //absent
        student.Participant = null
        student.statusKey = 0
        return
      }
      var participant = student.Participant = student.Participant[0]
      if(participant.totalAttempt == 0) {
        //joined only
        student.statusKey = 10
        return
      }
      //
      var have0Stars = (this.$_.filter(participant.Record,(o)=>{
        return o.topStar == 0
      }).length > 0)
      var haveLessThan3Stars = (this.$_.filter(participant.Record,(o)=>{
        return o.topStar < 3
      }).length > 0)
      //
      if(participant.Record.length < checkpoint.length) {
        if(have0Stars) {
          //gave up
          student.statusKey = 20
          return
        }
        if(haveLessThan3Stars) {
          //gave up
          student.statusKey = 30
          return
        }
        //bored
        student.statusKey = 40
        return
      }
      //
      if(have0Stars) {
        //tried
        student.statusKey = 50
        return
      }
      if(haveLessThan3Stars) {
        //completed
        student.statusKey = 60
        return
      }
      //mastered
      student.statusKey = 80
      return
    },
    processStatus(statusKey) {
      switch(statusKey) {
        case 0:
          return '缺席'
        case 10:
          return '起点止步'
        case 20:
          return '中途止步，须指导'
        case 30:
          return '中途止步'
        case 40:
          return '中途止步，表现佳'
        case 50:
          return '努力了，须指导'
        case 60:
          return '完成了'
        case 80:
          return '完成了，表现佳'
        default:
          return '不明'
      }
    },
    select(i) {
      this.selectedStudentIndex = i
    },
    go(num) {
      var x = this.$_.clone(this.selectedStudentIndex)
      x += num
      if(x < 0) x = null
      if(x >= this.filteredStudent.length) x = null
      this.selectedStudentIndex = x
    },
    updateFollowup(student,reason) {
      var si = this.$_.findIndex(this.filteredStudent,(o)=>{return o.id == student.id})
      if(this.filteredStudent[si].followup) {
        this.filteredStudent[si].followup = {
          ...this.filteredStudent[si].followup,
          reasonKey:reason.key
        }
      }
      else {
        this.filteredStudent[si].followup = {
          reasonKey:reason.key
        }
        this.followedStudent += 1
        if(this.followedStudent == this.filteredStudent.length) alert('恭喜您完成跟进了！🎉')
      }
      //
      if(this.followedStudent == this.filteredStudent.length) {
        //if edit a student after completed all followups
        this.select(null)
      }
      else {
        //if update a student and not yet complete followups
        this.go(1)
      }
    },
  },
};
</script>
<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>