<template>
<v-card>
  <v-card-title>#{{index+1}}. {{student.name}}</v-card-title>
  <v-card-subtitle>
    <v-chip small :color="(!student.needFollowup) ? 'success' : 'error'">{{status}}</v-chip>
  </v-card-subtitle>
  <template v-if="student.needFollowup">
    <v-card-text>
      <AError :api="api"></AError>
      <div class="text-h5">为什么没有完成所有课题呢？</div>
      <div class="text-h6 mt-3" v-if="reason">{{reason.text}} {{reason.desc}}</div>
    </v-card-text>
    <v-card-actions v-if="!reason" class="d-flex flex-column">
      <template v-for="(reason,ri) in reasons">
        <v-btn :color="(student.followup && student.followup.reasonKey == reason.key) ? 'yellow':'gray'" :key="ri" block class="mb-3 d-flex justify-start" @click="select(reason)">
          <div>{{reason.text}}</div>
          <div class="text-caption">{{reason.desc}}</div>
        </v-btn>
      </template>
    </v-card-actions>
    <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn class="mr-1" large @click="select(null)">{{$t("action.back")}}</v-btn>
        <v-btn :loading="api.isLoading" large color="success" @click="submit">{{$t("action.confirm")}}</v-btn>
    </v-card-actions>
  </template>
  <template v-else>
    <v-card-text>
      <div>参与度高，不必跟进。</div>
    </v-card-text>
  </template>
</v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    'index',
    'school',
    'event',
    'student',
    'status',
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    reason:null,
    reasons:[
      {
        text:'学生没兴趣',
        desc:'（例：懒散、觉得无聊、幼稚等）',
        key:'100_900',
      },
      {
        text:'问题太难',
        desc:'（例：学生因无法解答而放弃）',
        key:'100_400',
      },
      {
        text:'监护人不配合',
        desc:'（例：父母不许使用手机等）',
        key:'200',
      },
      {
        text:'设备欠缺',
        desc:'（例：没网线、没DATA、没电脑等）',
        key:'300',
      },
      {
        text:'手机或电脑有问题',
        desc:'（例：无法开启、没画面出现等）',
        key:'400',
      },
      {
        text:'理解错误',
        desc:'（例：学生搞错日期、不知道有其他页面等）',
        key:'500',
      },
      {
        text:'时机不好',
        desc:'（例：出门旅行等）',
        key:'600',
      },
      {
        text:'其他原因',
        key:'900',
      },
      {
        text:'原因不明',
        key:'999',
      },
    ],
  }),
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.log}/log/v1/${this.$_getLocale()}/followup/upsert`;
    this.api.method = 'post'
    this.api.params = {
      schoolId:this.school.id,
      classroomId:this.student.classroomId,
      studentId:this.student.id,
      eventId:this.event.id,
      participantId:(this.student.Participant) ? this.student.Participant.id : null,
      statusKey:this.student.statusKey,
      reasonKey:null,
    }
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    select(reason) {
      this.reason = reason
    },
    submit() {
      this.api.params.reasonKey = this.reason.key
      this.$api.fetch(this.api)
      this.$parent.updateFollowup(this.student,this.reason)
    },
  }
}
</script>